import { createContext, useContext } from "react"

export type SocialShareContent = {
    displaySocial: boolean
    setDisplaySocial:(c: boolean) => void
  }

export const SocialShareContext = createContext<SocialShareContent>({
    displaySocial: false,
    setDisplaySocial: () => {},
    })
export const useSocialShareContext = () => useContext(SocialShareContext)