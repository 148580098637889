import React, { RefObject, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip, TooltipRefProps } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

type Props = {
    children: React.ReactNode;
    path: string;
    title: string;
};
export const NavButton = ({ children, path, title }: Props) => {
    const tooltipRef: RefObject<TooltipRefProps> = useRef(null);
    const [ID, setID] = useState<string>("");
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setID(uuidv4());
        return () => {};
    }, []);

    return (
        <>
            <NavLink
                data-tooltip-id={ID}
                to={path}
                onMouseEnter={() => {
                    setShow(true);
                }}
                onMouseLeave={()=> {
                    setShow(false);
                }}
                className={({ isActive, isPending, isTransitioning }) =>
                    [
                        isPending ? "pending" : "",
                        isActive ? "active" : "",
                        isTransitioning ? "transitioning" : "",
                    ].join(" ")
                }
            >
                {children}
            </NavLink>

            {ID && (<Tooltip
                ref={tooltipRef}
                id={ID}
                offset={18}
                place="right"
                className="custom-tooltip"
                defaultIsOpen={false}
                isOpen={show}
                afterShow={() => {
                    if (tooltipRef.current && tooltipRef.current.activeAnchor) {
                        tooltipRef.current.activeAnchor.parentNode
                            ?.querySelector(".custom-tooltip")
                            ?.classList.add("animate-tooltip");
                    }
                }}
                afterHide={() => {
                    if (tooltipRef.current && tooltipRef.current.activeAnchor) {
                        tooltipRef.current.activeAnchor.parentNode
                            ?.querySelector(".custom-tooltip")
                            ?.classList.remove("animate-tooltip");
                    }
                }}
                content={title}
                style={{ backgroundColor: "#fff", color: "#000" }}
            />)}
        </>
    );
};

type NavTextProps = {
    onClick: () => void;
    path: string;
    title: string;
};
export const NavTextButton = ({ onClick, path, title }: NavTextProps) => {
    return (
        <>
            <NavLink
                to={path}
                onClick={onClick}
                className={({ isActive, isPending, isTransitioning }) =>
                    [
                        isPending ? "pending" : "",
                        isActive ? "active" : "",
                        isTransitioning ? "transitioning" : "",
                        "nav-txt-button",
                    ].join(" ")
                }
            >
                <span className="nav-no"></span>
                <span className="nav-name">{title}</span>
            </NavLink>
        </>
    );
};
