import { Routes } from "./routes/Routers";
import ErrorBoundary from "./components/errorBoundary";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import TextPlugin from "gsap/TextPlugin";
import { SpeedInsights } from "@vercel/speed-insights/react"
gsap.registerPlugin(ScrollTrigger,Draggable,TextPlugin)
function App() {
    return (
            <ErrorBoundary>
                <Routes />
                <SpeedInsights />
            </ErrorBoundary>
    );
}

export default App;
