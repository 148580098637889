import { RefObject, useRef } from "react";
import { isMobile } from "react-device-detect";
import { timeout } from "../helpers/common";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
type Props = {
    scrollContainerRef: RefObject<HTMLDivElement>;
}

export const MouseComponent = ({scrollContainerRef}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useGSAP(
        async () => {
            await timeout();
            gsap.set(containerRef.current, {  opacity: .5 });
            gsap.to(containerRef.current, {
                scrollTrigger: {
                    trigger: containerRef.current!.parentElement,
                    start: "bottom 100%",
                    end: "+=100px",
                    scrub: 1
                },
                opacity: 0,
                ease: "power1.inOut",
            });


            return () => {
                gsap.killTweensOf(containerRef.current)
            }

        },
        { scope: scrollContainerRef }
    );
    return (
        <div className="absolute full-screen-section mouse-scroll-animation" ref={containerRef}>
            <div className={`responsive-scroll${!isMobile ? " is-notTouch" : ""}`}>
                <span className="scroll-thisico"></span>
            </div>
        </div>

    );
};
