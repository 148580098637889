import { useSocialShareContext } from '../context/socialShareContext';
import { Outlet } from 'react-router-dom';
import NavbarLayout from './frameLayout/navBar';
import BottomLayout from './frameLayout/bottom';
import TopLayout from './frameLayout/top';


type Props = {}

const MainFrameLayout = (props: Props) => {
  const { displaySocial } = useSocialShareContext()

  return (
    <div className={`main-frame-container${ displaySocial ? " sm-main-frame" : ""}`}>
        <NavbarLayout/>
        <TopLayout/>
            <Outlet />
        <BottomLayout/>
    </div>
  )
}

export default MainFrameLayout;