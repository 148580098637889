import { useState } from "react";

import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import BackgroundLayout from "./background";
import SocialShareLayout from "./socialShare";
import MainFrameLayout from "./mainFrame";
import { SocialShareContext } from "../context/socialShareContext";
import { FullscreenContext } from "../context/fullscreenContext";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ScrollbarContext } from "../context/scrollbarContext";

export const PageContainer = () => {
    const [displaySocial, setDisplaySocial] = useState<boolean>(false);
    const [percentScroll, setPercentScroll] = useState<number>(0);
    const handle = useFullScreenHandle();
    const container = useRef<HTMLDivElement>(null);
    const [firstDisplay, setFirstDisplay] = useState(true);

    useGSAP(
        () => {
            if (!firstDisplay) return;
            gsap.fromTo(
                container.current,
                { scale: 0.5, opacity: 0 },
                { scale: 1, opacity: 1 }
            ); // <-- automatically reverted
            setFirstDisplay(false);
        },
        { scope: container }
    );

    return (
        <FullscreenContext.Provider value={handle}>
            <FullScreen handle={handle}>
                <SocialShareContext.Provider
                    value={{ displaySocial, setDisplaySocial }}
                >
                    <ScrollbarContext.Provider
                        value={{ percentScroll, setPercentScroll }}
                    >
                        <BackgroundLayout center={firstDisplay} />

                        <div
                            ref={container}
                            style={{
                                padding: ".5rem",
                                height: "calc(100dvh - 1rem)",
                                overflow: "hidden",
                            }}
                        >
                            <MainFrameLayout />
                            <SocialShareLayout />
                        </div>
                    </ScrollbarContext.Provider>
                </SocialShareContext.Provider>
            </FullScreen>
        </FullscreenContext.Provider>
    );
};
