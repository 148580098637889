import { RefObject, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { timeout } from "../../helpers/common";

import { ReactComponent as ReactLogo } from "../../assets/images/logos/react.svg";
import { ReactComponent as FlutterLogo } from "../../assets/images/logos/flutter.svg";
import { ReactComponent as NodejsLogo } from "../../assets/images/logos/nodejs.svg";
import { ReactComponent as ExpressjsLogo } from "../../assets/images/logos/expressjs.svg";
import { ReactComponent as PhpLogo } from "../../assets/images/logos/php.svg";
import { ReactComponent as MysqlLogo } from "../../assets/images/logos/mysql.svg";
import { ReactComponent as JavascriptLogo } from "../../assets/images/logos/javascript.svg";
import { ReactComponent as TypescriptLogo } from "../../assets/images/logos/typescript.svg";
import TempControls from "../tempControls";

interface TechsComponentProps {
    scrollContainerRef: RefObject<HTMLDivElement>;
}

export const TechsComponent = ({
    scrollContainerRef,
}: TechsComponentProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useGSAP(
        async () => {
            await timeout();
            gsap.set(containerRef.current, { translateY: "3rem", opacity: 0 });
            gsap.to(containerRef.current, {
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 90%",
                    end: "+=100px",
                    scrub: 1
                },
                duration: 2,
                opacity: 1,
                translateY: "0rem",
                ease: "power1.inOut",
            });
            const tl = gsap.timeline({
                scrollTrigger: {
                trigger: ".m-stack",
                start: "top 90%",
                end: "top 70%",
                scrub: 1,
                }
            });

            let words = gsap.utils.toArray(".m-stack");
            words.forEach((el,i) => {
                const element = el as HTMLElement;
                gsap.set(element, { translateY: "10px", opacity: 0 });
                tl.to(element, { duration: 2,
                    opacity: .55,
                    translateY: "0px",});
            });
        },
        { scope: scrollContainerRef }
    );
    return (
        <div className="main-life-stack-container sm-w-8-12 light-streak">
            <div className="folder-container relative" ref={containerRef}>
                <div className="folder-title-container">
                    <span className="text-sm">techs</span>
                    <TempControls/>
                </div>
                <div className="folder-body-container text-lg">
                    <div className="m-stack">
                        <ReactLogo className="max-h-14" />
                    </div>
                    <div className="m-stack">
                        <FlutterLogo className="max-h-14" />
                    </div>
                    <div className="m-stack">
                        <NodejsLogo className="max-h-14" />
                    </div>
                    <div className="m-stack">
                        <ExpressjsLogo className="max-h-14" />
                    </div>
                    <div className="m-stack">
                        <PhpLogo className="max-h-14" />
                    </div>
                    <div className="m-stack">
                        <MysqlLogo className="max-h-14" />
                    </div>
                    <div className="m-stack">
                        <JavascriptLogo className="max-h-14" />
                    </div>
                    <div className="m-stack">
                        <TypescriptLogo className="max-h-14" />
                    </div>
                </div>
            </div>
        </div>
    );
};
