import { useGSAP } from "@gsap/react";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import gsap from "gsap";
import { timeout } from "../helpers/common";
import { IconType } from "react-icons";
import { CgSpinnerAlt } from "react-icons/cg";

type Props = {
    children: React.ReactNode;
    disabled?: boolean | false;
    onClick?: () => void;
};
export const ButtonIcon = ({ children, onClick, disabled }: Props) => {
    return (
        <button
            className={`btn-icon${disabled ? " disabled" : ""}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

type linkProps = {
    children: React.ReactNode;
    href: string;
};
export const ButtonLink = ({ children, href }: linkProps) => {
    return (
        <a
            className="btn-icon bth-scale"
            href={href}
            target="_blank"
            rel="noreferrer"
        >
            {children}
        </a>
    );
};

type ButtonProps = {
  Icon: IconType;
    text: string;
    type: "button" | "submit" | "reset";
    isLoading?: boolean | false;
    txtLoading?: string | "loading";
    onClick?: () => void;
};
export const ButtonCustom = ({
  Icon,
    onClick,
    type,
    text,
    isLoading,
    txtLoading
}: ButtonProps) => {
    const [ID, setID] = useState<string>("");
    const containerRef = useRef<HTMLButtonElement>(null);

    const tl = useRef<GSAPTimeline>();
    const t2 = useRef<GSAPTimeline>();

    useEffect(() => {
        setID(uuidv4());
        return () => {};
    }, []);

    const { contextSafe } = useGSAP(
        async () => {
            await timeout();
            tl.current = gsap.timeline({paused: true});
            const label = Array.from(
                containerRef.current!.querySelectorAll(".btn-label span")
            ) as HTMLElement[];

            const icon = containerRef.current!.querySelector(
              ".btn-icon-right svg"
          );
            const count = label.length;
            let duration = .5 * count;
            const eachDuration = duration / (count * 2);
            const percent = 100 / (count * 2);

            label.forEach((el, i) => {
                const element = el as HTMLElement;
                tl.current?.to(
                    element,
                    {
                        duration: eachDuration,
                        yPercent: -100,
                        rotationX: -90,
                    },
                    i == 0 ? "" : `<+=${percent}%`
                );
                if(i == 0) {
                  tl.current?.to(icon, {
                            duration: 0.3,
                            xPercent: 100,
                        }, "<")
                }
            });
            label.forEach((el, i) => {
                const element = el as HTMLElement;
                tl.current?.to(
                    element,
                    { duration: 0, yPercent: 100, rotationX: -90 },
                    i == 0 ? `myLabel` : `<+=${percent}%`
                );
                if(i == 0) {
                  tl.current?.to(icon, {
                            duration: 0,
                            xPercent: -100,
                        }, "<")
                }
            });

            label.forEach((el, i) => {
                const element = el as HTMLElement;
                tl.current?.to(
                    element,
                    {
                        duration: eachDuration,
                        yPercent: 0,
                        rotationX: 0,
                    },
                    i == 0 ? `myLabel` : `<+=${percent}%`
                );
                if(i == 0) {
                  tl.current?.to(icon, {
                            duration: 0.3,
                            xPercent: 0,
                        }, "<")
                }
            });
            return () => {
                label.forEach((el, i) => {
                    const element = el as HTMLElement;
                    gsap.killTweensOf(element);
                });
                gsap.killTweensOf(icon);
                gsap.killTweensOf(containerRef.current);
            };
        },
        { scope: containerRef }
    );
    const onEnter = contextSafe(() => {
        tl.current?.play();
    });

    const onLeave = contextSafe(() => {
        tl.current?.reverse();
    });

    return (
        <button
            className={`btn-custom`}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            type={type}
            onClick={onClick}
            ref={containerRef}
            disabled={isLoading}
        >
            <span className="btn-label" style={{ display: isLoading ? "none" : "" }}>
                {text.split("").map((v, i) => {
                    return (
                        <span className="txt" key={`${ID}-label-${i}`}>
                            {v}
                        </span>
                    );
                })}
            </span>
            <span className="btn-icon-right" style={{ display: isLoading ? "none" : "" }}>
                {<Icon/>}
            </span>
            {
                isLoading &&
                    <><span className="btn-label-loading">
                    {txtLoading || "loading..."}
                </span><span className="bth-icon-loading">
                        <CgSpinnerAlt />
                    </span></>

            }
        </button>
    );
};
