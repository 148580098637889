// CustomAlert.tsx
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';

interface CustomAlertProps {
  message: string;
  onClose: () => void;
  duration?: number;
}

const AlertWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: #0c0c0cc8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  text-align: center;
  border: 1px solid #444444;
  z-index: 1000;
  opacity: 0;
`;

const AlertBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  opacity: 0;
`;

const CustomAlert: React.FC<CustomAlertProps> = ({ message, onClose, duration = 3 }) => {
  const alertRef = useRef<HTMLDivElement>(null);
  const backdropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fadeIn = gsap.to(alertRef.current, { opacity: 1, duration: 0.3 });
    gsap.to(backdropRef.current, { opacity: 1, duration: 0.3 });

    const fadeOut = gsap.timeline({ delay: duration });
    fadeOut.to(alertRef.current, { opacity: 0, duration: 0.3 });
    fadeOut.to(backdropRef.current, { opacity: 0, duration: 0.3 }, "<");
    fadeOut.eventCallback("onComplete", onClose);

    return () => {
      fadeIn.kill();
    //   fadeOut.kill();
    };
  }, [duration, onClose]);

  return (
    <>
      <AlertBackdrop ref={backdropRef} onClick={onClose} />
      <AlertWrapper ref={alertRef}>
        <p>{message}</p>
        {/* <button onClick={onClose}>Close</button> */}
      </AlertWrapper>
    </>
  );
};

export default CustomAlert;
