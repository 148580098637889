import { createContext, useContext } from "react";

export type ScrollbarContent = {
  percentScroll: number;
  setPercentScroll: (c: number) => void;
};

export const ScrollbarContext = createContext<ScrollbarContent>({
  percentScroll: 0,
  setPercentScroll: () => {},
});
export const useScrollbarContext = () => useContext(ScrollbarContext);
