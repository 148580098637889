import { useRef } from 'react'

import gsap from "gsap";
import { useGSAP } from '@gsap/react';

import '../assets/marquee.scss'
import { horizontalLoop } from '../helpers/horizontallLoop';

interface IAppProps {
  texts: string[];
}
export default function Marquee ({texts}: IAppProps) {
  const boxesContainer = useRef(null);

  useGSAP(
    () => {
      const boxes = gsap.utils.toArray('.marquee-box');
      horizontalLoop(boxes, { repeat: -1 , speed: 0.5});
    },
    {
      scope: boxesContainer,
    }
  )

  return (
    <div className="marquee-container" ref={boxesContainer}>
      {texts.map((el, index) => (
        <div key={`marquee-box-${index}`} className="marquee-box">
          {el}
        </div>
      ))}
    </div>
  );
}
