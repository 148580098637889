import { createContext, useContext } from "react";

export type FullscreenContent = {
    active: boolean;
    enter: () => Promise<void>;
    exit: () => Promise<void>;
    node: React.MutableRefObject<HTMLDivElement | null>;
};

export const FullscreenContext = createContext<FullscreenContent>({
    active: false,
    enter: async () => {},
    exit: async () => {},
    node: { current: null },
});
export const useFullscreenContext = () => useContext(FullscreenContext);
