import { useSocialShareContext } from '../../context/socialShareContext';
import { ButtonIcon } from '../../components/button';
import { VscChromeClose, VscChromeMaximize, VscChromeMinimize, VscAdd, VscChromeRestore } from "react-icons/vsc";
import { useFullscreenContext } from '../../context/fullscreenContext';
import { Link } from 'react-router-dom';

type Props = {}

const TopLayout = (props: Props) => {
    const { displaySocial, setDisplaySocial } = useSocialShareContext()
    const {enter, exit, active} = useFullscreenContext()


  return (
    <div className='main-frame-top-container'>
        <Link to='/' className='main-frame-title'>APISIT&nbsp;•&nbsp;<span className='sub-title'>PROMYOS</span></Link>
        <div className='main-frame-controls'>
            <ButtonIcon onClick={() => setDisplaySocial(!displaySocial)}>{ displaySocial ? <VscAdd /> : <VscChromeMinimize />}</ButtonIcon>
            <ButtonIcon onClick={() => {if(active) exit(); else enter();}}>{active ? <VscChromeRestore /> :<VscChromeMaximize /> }</ButtonIcon>
            <ButtonIcon onClick={() => {}}><VscChromeClose /></ButtonIcon>
        </div>
    </div>
  )
}

export default TopLayout;