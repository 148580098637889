import { VscChromeClose, VscChromeMaximize, VscChromeMinimize } from "react-icons/vsc";
import { ButtonIcon } from "./button";

const TempControls = () => {
    return (
        <div className="folder-controls">
            <ButtonIcon disabled>
                <VscChromeMinimize />
            </ButtonIcon>
            <ButtonIcon disabled>
                <VscChromeMaximize />
            </ButtonIcon>
            <ButtonIcon disabled>
                <VscChromeClose />
            </ButtonIcon>
        </div>
    );
};
export default TempControls;

