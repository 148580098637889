import { RefObject } from "react";
import { BoxCustomComponent, BoxCustomProps } from "../boxCustom";

interface ExperienceComponentProps {
    scrollContainerRef: RefObject<HTMLDivElement>;
    children: BoxCustomProps[];
}

export const ExperienceComponent = ({
    scrollContainerRef,
    children
}: ExperienceComponentProps) => {

    return (
        <div className="main-experience-container sm-w-8-12 light-streak">
            {children.map((child, i) => <BoxCustomComponent key={i} scrollContainerRef={scrollContainerRef} child={child}/>)}
        </div>
    );
};
