import { useState } from 'react'
import { TbBolt, TbMail, TbSmartHome, TbUser } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { NavButton, NavTextButton } from '../../components/nav';
import { Turn as Hamburger } from 'hamburger-react'

type Props = {}

const NavbarLayout = (props: Props) => {
    const [isClosed, setIsClosed] = useState<boolean>(true);
  return (
        <header className='main-frame-header-container'>
            <div className='main-logo'>
                <Link to="/">
                    <div className='logo-o'></div>
                    <div className='logo-i'></div>
                </Link>
            </div>


            <Link to='/' className='main-frame-title'>APISIT&nbsp;•&nbsp;<span className='sub-title'>PROMYOS</span></Link>
            <div className='main-sm-menu'>
                <Hamburger toggled={!isClosed} onToggle={() => setIsClosed(!isClosed)} direction="left" duration={0.5} easing='ease-in-out' color='#fff' size={18}></Hamburger>
            </div>

            <div className={`main-sm-menu-container${ !isClosed ? " active-sm-menu": ""}`}>
                <ul>
                    <li>
                        <NavTextButton onClick={() => setIsClosed(!isClosed)} path="/" title='home'/>
                    </li>

                    <li>
                        <NavTextButton onClick={() => setIsClosed(!isClosed)} path="/about" title='about'/>
                    </li>

                    <li>
                        <NavTextButton onClick={() => setIsClosed(!isClosed)} path="/experience" title='experience'/>
                    </li>

                    <li>
                        <NavTextButton onClick={() => setIsClosed(!isClosed)} path="/contact" title='contact'/>
                    </li>

                </ul>
            </div>

            <nav>
                <ul className='nav-container'>
                    <li>
                        <NavButton path="/" title='home'><TbSmartHome /></NavButton>
                    </li>

                    <li>
                        <NavButton path="/about" title='about'><TbUser /></NavButton>
                    </li>

                    <li>
                        <NavButton path="/experience" title='experience'><TbBolt /></NavButton>
                    </li>

                    <li>
                        <NavButton path="/contact" title='contact'><TbMail /></NavButton>
                    </li>

                </ul>
            </nav>

        </header>

  )
}

export default NavbarLayout;