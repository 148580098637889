import { FormEvent, useLayoutEffect, useRef, useState } from 'react'
import ScrollContainer from '../components/ScrollContainer';
import Marquee from '../components/marquee';
import { MouseComponent } from '../components/mouse';
import TempControls from '../components/tempControls';
import CustomInput from '../components/input';
import { ButtonCustom } from '../components/button';
import { TbMail } from "react-icons/tb";
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import CustomAlert from '../components/customAlert';

const texts = ["CONTACT", "ME"];

export const Contact = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [yourMessage, setYourMessage] = useState<string>('');

  const [fullNameError, setFullNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [yourMessageError, setYourMessageError] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);

  const handleFullNameChange = (value: string): void => {
    setFullName(value);
    setFullNameError('');  // Clear error message on change
  };

  const handleEmailChange = (value: string): void => {
    setEmail(value);
    setEmailError('');  // Clear error message on change
  };

  const handleYourMessageChange = (value: string): void => {
    setYourMessage(value);
    setYourMessageError('');  // Clear error message on change
  };

  const validateFullName = (value: string): string => {
    if (value.trim() === '') {
      return 'This field is required';
    }
    return '';
  };

  const validateEmail = (value: string): string => {
    if (value.trim() === '') {
      return 'This field is required';
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      return 'Please provide a valid e-mail.';
    }
    return '';
  };

  const validateYourMessage = (value: string): string => {
    if (value.trim() === '') {
      return 'This field is required';
    }
    return '';
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const fullNameError = validateFullName(fullName);
    const emailError = validateEmail(email);
    const yourMessageError = validateYourMessage(yourMessage);

    setFullNameError(fullNameError);
    setEmailError(emailError);
    setYourMessageError(yourMessageError);


    if (!fullNameError && !emailError && !yourMessageError && recaptchaRef.current) {
      // Handle form submission
      // console.log('Form submitted with values:', { fullName, email, yourMessage });
      setIsLoading(true);

      try {
        const token = await recaptchaRef.current.executeAsync()

        emailjs.send('service_y109n8s', 'template_p28tzey', {
          from_name: fullName,
          message: yourMessage,
          from_email: email,
          "g-recaptcha-response": token
          }, 'vupxWmtt5iCW30-20')
          .then((result) => {
              setIsLoading(false);
              setTextAlert("I have received your message.");
              handleShowAlert();
          }, (error) => {
              setIsLoading(false);
              setTextAlert("An error occurred, please try again.")
              handleShowAlert();
          });
      } catch (error) {
        setIsLoading(false);
              setTextAlert("An error occurred, please try again.")
              handleShowAlert();
      }
    }
  };
  const [textAlert, setTextAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  useLayoutEffect(() => {
    document.body.setAttribute("data-theme", "purple");
});
  return (
    <ScrollContainer nodeRef={scrollContainerRef}>

    <main className='content-container' style={{ paddingBottom: "0rem" }}>
      <section className='section-content-container'>
        <div className='inset-0 full-screen-section-container'>
          <div className='absolute full-screen-section' style={{ top: 0, left: 0 , zIndex: 0}}>
            <Marquee texts={texts}/>
          </div>
          <div className='full-screen-section intro-content-container'>
            <span className='title-page'>CONTACT</span>
            <h1>Let's Connect or  <br/>
              <span>Send a Message</span></h1>
            <p>Whether you have a project you'd like to collaborate on or just want to chat, you're in the right place. Contact us today!</p>
          </div>
          <MouseComponent scrollContainerRef={scrollContainerRef}/>
        </div>
      </section>
      <section className="section-content-container">
          <div className="inset-0 normal-content-container" style={{ display: "flex", justifyContent: "center", overflow: "unset", padding: "1rem" }}>
            <div className="main-contact-container max-w-2xl light-streak">
            <div className="folder-container relative">
                <div className="folder-title-container">
                    <span className="text-sm">write-message</span>
                    <TempControls/>
                </div>
                <div className="folder-body-container text-lg">
                <form className='from-contact-input-container' onSubmit={handleSubmit} noValidate>
                  <CustomInput
                  no='01'
                    type="text"
                    label="full-name"
                    required={true}
                    value={fullName}
                    onChange={handleFullNameChange}
                    errorMessage={fullNameError}
                  />
                  <CustomInput
                  no='02'
                    type="email"
                    label="email"
                    required={true}
                    value={email}
                    onChange={handleEmailChange}
                    errorMessage={emailError}
                  />
                  <CustomInput
                  no='03'
                    type="textarea"
                    label="your-message"
                    required={true}
                    value={yourMessage}
                    onChange={handleYourMessageChange}
                    errorMessage={yourMessageError}
                  />
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6Le5owgqAAAAAPs4i7_eJiRkEmg7Kq_WfZmXYWn8"
                  />
                  <div className='submit-button-container'>

                    <ButtonCustom type="submit" text='send-a-message' Icon={TbMail} txtLoading='sending' isLoading={isLoading}></ButtonCustom>
                  </div>
                </form>
                </div>
            </div>
            </div>
          </div>

      </section>
    </main>
    {showAlert && <CustomAlert message={textAlert} onClose={handleCloseAlert} duration={2} />}

    </ScrollContainer>
  )
}