
import React, {  } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import gsap from "gsap";

const TransitionComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  return (
      <SwitchTransition>
        <Transition
          key={location.pathname}
          timeout={500}
          onEnter={(node: HTMLElement) => {
            gsap.set(node, { scale: 0.5, opacity: 1 });
            gsap
              .timeline({ paused: true })
              .to(node, { scale: 1,  opacity: 1, duration: 0.5})
              .play();
          }}
          onExit={(node: HTMLElement) => {
            gsap
              .timeline({ paused: true })
              .to(node, { yPercent: 10,  opacity: 0, duration: 0.25 })
              .play();
          }}
        >
            {children}

        </Transition>
      </SwitchTransition>

  );
};

export default TransitionComponent;
