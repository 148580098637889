import { RefObject, useEffect, useRef, useState } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { timeout } from "../helpers/common";
import { IoChevronUp } from "react-icons/io5";
import { IoLocationSharp  } from "react-icons/io5";
import { BsFillArrowUpRightSquareFill } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";

interface Props {
    scrollContainerRef: RefObject<HTMLDivElement>;
    child: BoxCustomProps;
}

type titleProps = {
    value: string,
    sm?: boolean | false
}

type linkProps = {
    text: string,
    url: string
}

export type BoxCustomProps = {
    index: string,
    title: titleProps[],
    year: string,
    tags: string[],
    companyLogo: string,
    location: string,
    companyUrl: linkProps,
    companyDescription: string
}

export const BoxCustomComponent = ({
    scrollContainerRef,
    child
}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const detailRef = useRef<HTMLDivElement>(null);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const observer = useRef<any>(null);
    const [ID, setID] = useState<string>("");

    const {index, title, year, tags, companyLogo, location, companyUrl, companyDescription} = child;

    useEffect(() => {
        setID(uuidv4());
        return () => {};
    }, []);
    useGSAP(
        async () => {
            await timeout();
            gsap.set(containerRef.current, { translateY: "3rem", opacity: 0 });
            gsap.to(containerRef.current, {
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 90%",
                    end: "+=100px",
                    scrub: 1
                },
                duration: 2,
                opacity: 1,
                translateY: "0rem",
                ease: "power1.inOut",
            });
            const tl = gsap.timeline({
                scrollTrigger: {
                trigger: containerRef.current,
                start: "top 90%",
                end: "bottom 100%",
                scrub: 1,
                }
            });
            const allElements: HTMLElement[][] = [];
            const indexes = Array.from(containerRef.current!.querySelectorAll(".box-index-container span") ) as HTMLElement[];
            const titles = Array.from(containerRef.current!.querySelectorAll(".box-title-container span")) as HTMLElement[];
            const words = Array.from(containerRef.current!.querySelectorAll(".tag-warp")) as HTMLElement[];
            const count = indexes.length + titles.length + words.length;
            allElements.push(indexes);
            allElements.push(titles);
            allElements.push(words);
            allElements.forEach(e1 => {
                e1.forEach((el,i) => {
                    const element = el as HTMLElement;
                    gsap.set(element, { scale: .5, opacity: 0 });
                    tl.to(element, {
                        duration: .5 / count,
                        opacity: 1,
                        scale: 1,});
                });
            })

            return () => {
                tl.kill();
                allElements.forEach(e1 => {
                    e1.forEach((el,i) => {
                        const element = el as HTMLElement;
                        gsap.killTweensOf(element)
                    });
                })
                gsap.killTweensOf(containerRef.current)
            }

        },
        { scope: scrollContainerRef }
    );

    useEffect(() => {
        const body = document.getElementsByTagName("BODY")[0];

        if (observer.current !== null) {
            observer.current?.unobserve(body);
        }
        observer.current = new ResizeObserver((entries) => {
            toggleDetail(showDetail);
            const detailEntry = entries.find(entry => entry.target === detailRef.current);
            if(showDetail && detailRef.current && detailEntry) {
                const height = detailRef.current.offsetHeight;

                gsap.to(detailRef.current.parentElement, {
                    opacity: 1,
                    height: height,
                    duration: 0,
                    ease: "none"
                })
            }
        });
        observer.current?.observe(body);

        return () => {
            if (observer.current) {
                observer.current?.unobserve(body);
            }
        };
    }, [showDetail]);

    const toggleDetail = (value: boolean) => {
        setShowDetail(value);
        if(value && detailRef.current) {
            const height = detailRef.current.offsetHeight;

            gsap.to(detailRef.current.parentElement, {
                opacity: 1,
                height: height,

                duration: .5,
                ease: "none"
            })
        } else if(!value && detailRef.current){
            gsap.to(detailRef.current.parentElement, {
                opacity: 0,
                height: 0,
                duration: .5,
                ease: "none"
            })
        }
    }
    return (
            <div className="box-custom-container relative" ref={containerRef}>
                <div className="box-body-container" onClick={() => toggleDetail(!showDetail)}>
                   <div className="box-index-container">
                    {index.split("").map((v,i) => {
                        return <span key={`${ID}-index-${i}`}>{v}</span>
                    })}</div>
                   <div className="box-title-container">
                    {title.map((el, i) => {
                            const text = el.value.split("");
                            const textLength = text.length - 1;
                            return text.map((v, j) => {
                                const classList = [];
                                if(el.sm) classList.push("sm-txt");
                                if(textLength === j) classList.push("space");
                                return (
                                    <span key={`${ID}-${i}-${j}`} className={classList.join(" ")}>{v}</span>
                                )
                            }
                            )
                        })}
                    </div>
                   <div className="box-year-container">{year}</div>
                   <div className="box-empty-container"></div>
                   <div className="box-tags-container">
                   {tags.map((v, i) => {

                                return (
                                    <div key={`${ID}-tag-${i}`} className="tag-warp">{v}</div>
                                )
                        })}
                   </div>
                   <div className={`box-button-container`}>
                    <div className={`box-button${showDetail ? " active" : ""}`}>
                        <IoChevronUp />
                    </div>
                   </div>
                </div>


                <div className={`box-detail-container`}>
                    <div className="box-detail company-detail" ref={detailRef}>
                        <div className="company-logo">
                            <img src={companyLogo} alt="company-logo"></img>
                        </div>
                        <div className="company-work">
                            <div className="company-top">
                                <div className="company-top-txt">
                                    <IoLocationSharp />
                                    <span>{location}</span>
                                </div>
                                <div className="company-top-txt">
                                <BsFillArrowUpRightSquareFill  />
                                    <a href={companyUrl.url} target='_blank' rel="noreferrer">{companyUrl.text}</a>
                                </div>
                            </div>
                            <div className="company-i-work">
                            {companyDescription}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};
