import React, {
    ReactNode,
    RefObject,
    useEffect,
    useRef,
} from "react";
import { useScrollbarContext } from "../context/scrollbarContext";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import Lenis from 'lenis';
interface ScrollContainerProps {
    children: ReactNode;
    nodeRef: RefObject<HTMLDivElement>;
}

const ScrollContainer: React.FC<ScrollContainerProps> = ({
    children,
    nodeRef,
}) => {
    const { setPercentScroll } = useScrollbarContext();

    const lenisRef = useRef<any>(null)

    const updatePercentScroll = (progress: number) => {
        setPercentScroll(progress * 100);
    };
    useGSAP(
        async () => {
                ScrollTrigger.defaults({
                    scroller: nodeRef.current!,
                });

            const lenis = new Lenis({
                wrapper: nodeRef.current as HTMLElement,
                // duration: 1.2,
                // easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
                // smoothWheel: true,
              });
              lenisRef.current = lenis;
              const raf = (time: any) => {
                lenis.raf(time);
                ScrollTrigger.update();
                requestAnimationFrame(raf);
                updatePercentScroll(lenis.progress )
              };

              requestAnimationFrame(raf);
              updatePercentScroll(lenis.progress )

              const resizeObserver = new ResizeObserver(() => {
                if (lenis) {
                    lenis.resize();
                }
              });

              resizeObserver.observe(nodeRef.current!.children[0]);

        },
        { scope: lenisRef },
    );

    useEffect(() => {

      })

    return (
        <div className="main-frame-content-container" ref={nodeRef}>
            {children}
        </div>
    );
};

export default ScrollContainer;
