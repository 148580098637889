import { RefObject, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { timeout } from "../helpers/common";
import { v4 as uuidv4 } from "uuid";

type textProps = {
    value: string,
    primary?: boolean | false
}

type Props = {
    scrollContainerRef: RefObject<HTMLDivElement>;
    texts: textProps[];
}

const IntroTitleComponent = ({scrollContainerRef, texts}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [ID, setID] = useState<string>("");
    const [initCompleted, setInitCompleted] = useState(false)

    useEffect(() => {
        setID(uuidv4());
        return () => {};
    }, []);

    useEffect(() => {
      if(scrollContainerRef.current && containerRef.current && !initCompleted) {
            setInitCompleted(true);
            timeout().then(() => {
            const tl = gsap.timeline({
                scrollTrigger: {
                trigger: containerRef.current,
                start: "top 90%",
                end: "bottom 90%",
                scrub: 1
                }
            });

            let words = containerRef.current!.querySelectorAll("span");
            words.forEach((el) => {
                const element = el as HTMLElement;
                tl.to(element, {opacity: 0, scale: 0.4, translateY: "1rem"});
                tl.to(element, {opacity: 1, scale: 1, translateY: "0rem"});
            });
        });
    }

    }, [scrollContainerRef, containerRef, initCompleted])



  return (
    <div className="main-title-intro-container" ref={containerRef}>
        {texts.map((el, i) => {
            const text = el.value.split("");
            const textLength = text.length - 1;
            return text.map((v, j) => {
                const classList = [];
                if(el.primary) classList.push("text-primary");
                if(textLength === j) classList.push("space");
                return (
                    <span key={`${ID}-${i}-${j}`} className={classList.join(" ")}>{v}</span>
                )
            }
            )
        })}
    </div>
  )
}

export default IntroTitleComponent