import { useRef } from 'react'
import gsap from "gsap";
import { useSocialShareContext } from '../context/socialShareContext';
import { SwitchTransition, Transition } from 'react-transition-group';
import { ButtonIcon, ButtonLink } from '../components/button';
import { VscChromeClose } from 'react-icons/vsc';
import { BiLogoFacebookSquare, BiLogoLinkedinSquare } from 'react-icons/bi';
import { TbMail } from 'react-icons/tb';
import { FaXTwitter } from "react-icons/fa6";

type Props = {}

const SocialShareLayout = (props: Props) => {
    const container = useRef<HTMLDivElement>(null);
    const { displaySocial, setDisplaySocial } = useSocialShareContext()

    return (
        <SwitchTransition>
        <Transition
            key={displaySocial ? 'socialEnable' : 'socialDisable'}
            timeout={1000}
            onEnter={(node: HTMLElement) => {
                if(!node) return;
                gsap.fromTo(node, {translateX: "100%", translateY: "100%",ease: "power1.inOut", duration: 1}, {translateX: "0%", translateY: "0%", ease: "power1.inOut", duration: 1});
            }}
            onExit={(node: HTMLElement) => {
                if(!node) return;
                gsap.fromTo(node, {translateX: "0%", translateY: "0%",ease: "power1.inOut", duration: 1}, {translateX: "100%", translateY: "100%", ease: "power1.inOut", duration: 1});
            }}
        >
            {displaySocial ?
            <div ref={container} style={{ zIndex: 80, bottom: "0.5rem", right: "0.5rem", position: "fixed"}}>
                <div className='social-share-container social-share'>
                    <div className='social-share-title-container'>
                        <span className='text-sm'>social-share</span>
                        <ButtonIcon onClick={() => setDisplaySocial(!displaySocial)}><VscChromeClose /></ButtonIcon>
                    </div>
                    <div className='social-share-body-container text-lg'>
                        <span>share</span>
                        <ButtonLink href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}><BiLogoFacebookSquare /></ButtonLink>
                        <ButtonLink href={`https://twitter.com/intent/tweet?url=${window.location.href}`}><FaXTwitter  /></ButtonLink>
                        <ButtonLink href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}><BiLogoLinkedinSquare /></ButtonLink>
                        <ButtonLink href={`mailto:?body=${window.location.href}`}><TbMail /></ButtonLink>
                    </div>

                </div>
            </div>
            : <></>}
        </Transition>
        </SwitchTransition>

    )
}

export default SocialShareLayout;