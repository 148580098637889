import { useEffect, useRef, useState } from 'react'
import gsap from "gsap";
import { useScrollbarContext } from '../context/scrollbarContext';

type Props = {
    center: boolean;
}

const BackgroundLayout = (props: Props) => {
    const container = useRef<HTMLDivElement>(null);
    const { center } = props;
    const [isMounted, setIsMounted] = useState(false);
    const { percentScroll } = useScrollbarContext()

    const  initAnimation = async () => {
        const s1 = 1;
        const s2 = 0.6;
        const d = 12;
        await new Promise((resolve, reject) => {
           setTimeout(() => {
                gsap.to(".aspect-square", {
                    transition: "none",
                    duration: 0,
                })
              resolve(true)

           }, 1000);
          });

        const bgRight = gsap.timeline( {
                onReverseComplete: () => {
                    bgRight.play();
                },
                onComplete: () => {
                    bgRight.reverse();
                }
            }).fromTo(".bg-tr .size-full", {scale: s1, }, {
                duration: d,
                scale: s2,
                ease: "power1.inOut",
              });


            const bgLeft = gsap.timeline( {
                onReverseComplete: () => {
                    bgLeft.play();
                },
                onComplete: () => {
                    bgLeft.reverse();
                }
            })
            .fromTo(".bg-bl .size-full", {scale: s2}, {
                duration: d,
                scale: s1,
                ease: "power1.inOut",
              });
    }

    useEffect(() => {
        if(!center && !isMounted) {
            initAnimation();
            setIsMounted(true);
        }
        return () => {
        }
    }, [center])


    useEffect(() => {
        if(isMounted) {
            gsap
            .timeline({ paused: true })
            .to(".bg-tr", { y: `-${percentScroll}%`, top: `${percentScroll}%`, duration: 1,})
            .play();

            gsap
            .timeline({ paused: true })
            .to(".bg-bl", { y: `${percentScroll}%`, bottom: `${percentScroll}%`, duration: 1,})
            .play();

        }

    }, [percentScroll]);

  return (
    <div className='absolute inset-0' ref={container}>
        <div className={`aspect-square bg-circle bg-tr${!isMounted ? " bg-center": ""}`}>
            <div className='size-full rounded-full '></div>
        </div>
        <div className={`aspect-square bg-circle bg-bl${!isMounted ? " bg-center": ""}`}>
            <div className='size-full rounded-full '></div>
        </div>
    </div>
  )
}

export default BackgroundLayout;