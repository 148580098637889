import { useLayoutEffect, useRef } from 'react'

import "../../assets/home.scss";
import Marquee from '../../components/marquee';
import ScrollContainer from '../../components/ScrollContainer';

const texts = ["HELLO", "WORLD"];

export const Home = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
        document.body.setAttribute("data-theme", "blue");
  });

  return (
    <ScrollContainer nodeRef={scrollContainerRef}>

    <main className='content-container' style={{ paddingBottom: "0rem" }}>
      <section className='section-content-container'>
        <div className='inset-0 full-screen-section-container'>
          <div className='absolute full-screen-section' style={{ top: 0, left: 0 , zIndex: 0}}>
            <Marquee texts={texts}/>
          </div>
          <div className='full-screen-section intro-content-container'>
            <span className='title-page'>HOME</span>
            <h1>Hi, I'm Apisit, a <br/>
              <span>software</span> developer</h1>
            <p>I bring value to development projects by merging technical expertise with creativity and aesthetics.</p>
          </div>
        </div>
      </section>
    </main>
    </ScrollContainer>

  );
}