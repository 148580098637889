import React, { useRef } from "react";
import useAutosizeTextArea from "../hooks/useAutosizeTextArea";

interface CustomInputProps {
    type: "text" | "email" | "textarea"; // Specify the type of input
    label: string;
    value: string;
    required?: boolean;
    no?: string;
    onChange: (value: string) => void;
    errorMessage: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
    type,
    label,
    value,
    required,
    no,
    onChange,
    errorMessage,
}) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useAutosizeTextArea(textAreaRef.current, value, 181);
    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        onChange(e.target.value);
    };

    return (
        <div className={`input-container text-sm${no ? " has-no": ""}`}>
            {no && <div className="input-no">{no}</div>}
            <div className="from-control">
                <label htmlFor={label}>{label}{required && <span className="input-required">*</span>}</label>
                {type === "textarea" ? (
                    <textarea
                        id={label}
                        value={value}
                        ref={textAreaRef}
                        rows={6}
                        onChange={handleChange}
                    />
                ) : (
                    <input
                        type={type === "email" ? "email" : "text"}
                        id={label}
                        value={value}
                        onChange={handleChange}
                        autoComplete="off"
                        onError={(a) => {
                            console.log(a);
                        }}
                        aria-invalid={false}
                        onInvalid={(a) => {
                            console.log(a);
                            return true;
                        }}
                    />
                )}
                {errorMessage && <p className="input-error-message" style={{ color: "red" }}>{errorMessage}</p>}
            </div>
        </div>
    );
};

export default CustomInput;
