import { useEffect, useState } from 'react'
import { ButtonLink } from '../../components/button';
import { BiLogoFacebookSquare, BiLogoGithub, BiLogoInstagram, BiLogoLinkedinSquare } from "react-icons/bi";
import thaiFlag from '../../assets/images/thailand.webp';

type Props = {}

const BottomLayout = (props: Props) => {
    const [today, setDate] = useState(new Date());

    const updateDate = () => {
        const now = new Date();
        const bangkokOffset = 7 * 60;
        const localOffset = now.getTimezoneOffset();

        const bangkokTime = new Date(now.getTime() + (bangkokOffset + localOffset) * 60000);
        setDate(bangkokTime);
      };

    useEffect(() => {
       updateDate();
        const timer = setInterval(updateDate, 60 * 1000);
      return () => {
        clearInterval(timer);
      }
    }, []);

  return (

        <div className='main-frame-bottom-container'>
            <div className='bottom-dot'>
                <div className='blob'></div>
            </div>
            <aside>
                <div className='local-time-container'>
                    <span className='location'>Based in <span className='highlight'>Bangkok</span><img className='local-flag' src={thaiFlag} alt='thai-flag'></img></span>
                    <span className='location'>Local time <span className='highlight'>{today.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })}</span></span>
                </div>
                <div className='social-profiles'>
                    <ButtonLink href='https://www.linkedin.com/in/apisit-promyos-074311201/'><BiLogoLinkedinSquare /></ButtonLink>
                    <ButtonLink href="https://www.instagram.com/tatateetatata/"><BiLogoInstagram /></ButtonLink>
                    <ButtonLink href='https://www.facebook.com/ApisitPromyos/'><BiLogoFacebookSquare /></ButtonLink>
                    <ButtonLink href='https://github.com/treblemakers'><BiLogoGithub /></ButtonLink>
                </div>
            </aside>
        </div>
  )
}

export default BottomLayout;