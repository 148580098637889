import { useLayoutEffect, useRef } from "react";
import Marquee from "../components/marquee";
import { useGSAP } from "@gsap/react";
import ScrollContainer from "../components/ScrollContainer";
import { timeout } from "../helpers/common";
import { MeComponent } from "../components/about/me";
import IntroTitleComponent from "../components/introTitle";

import { TechsComponent } from "../components/about/techs";
import { MouseComponent } from "../components/mouse";
const texts = ["APISIT", "PROMYOS"];

export const About = () => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        document.body.setAttribute("data-theme", "green");
    });
    const proxyRef = useRef<HTMLDivElement>(null);
    useGSAP(
        async () => {
            await timeout();
//             // Create the timeline with repeat, repeatDelay, and yoyo properties
// const tl = gsap.timeline({
//     // repeat: 3,
//     // repeatDelay: 1,
//     scrollTrigger: {
//       trigger: ".main-title-intro-container",
//       start: "top 80%",
//         end: "+=50px",
//         markers: true,
//       scrub: 1
//     }
//   });

//   let words = gsap.utils.toArray(".main-title-intro-container span")
//   console.log(words)
//   document.querySelector(".main-title-intro-container span")

//   words.forEach((el) => {
//     const element = el as HTMLElement;
//     tl.fromTo(element, {text: "", ease: "none"}, {text: element.innerHTML});
//   });
  // Add animations to the timeline
//   tl.from(".main-title-intro-container span.s", {
//     text: "",
//   })
//   .from(".main-title-intro-container span.text-primary", {
//     text: "",
//   });
        //     let words = gsap.utils.toArray(".test"),
        //     tl = gsap.timeline({delay: 0.5}),
        //     timePerCharacter = 0.2;
        //           console.log(words)
        // words.forEach((el: unknown) => {
        //   const element = el as HTMLElement;
        // //   gsap.from(element, {
        // //     scrollTrigger: {
        // //         trigger: element,
        // //         // toggleActions: "restart none reverse none",
        // //         start: "top 80%",
        // //         end: "top 100%",
        // //         scrub: 1
        // //     },
        // //     duration: 1,
        // //     opacity: 1,
        // //     translateY: "0rem",
        // //     ease: "power1.inOut",
        // // });
        //   tl.from(element, {text: "", duration: element.innerHTML.length * timePerCharacter, ease: "none"});
        // });

        },
        { scope: scrollContainerRef }
    );

    return (
        <ScrollContainer nodeRef={scrollContainerRef}>
            <main className="content-container">
                <section className="section-content-container">
                    <div className="inset-0 full-screen-section-container">
                        <div
                            className="absolute full-screen-section"
                            style={{ top: 0, left: 0, zIndex: 0 }}
                        >
                            <Marquee texts={texts} />
                        </div>
                        <div className="full-screen-section intro-content-container">
                            <span className="title-page">ABOUT</span>
                            <h1 >
                                Let’s get to <br />
                                know <span> each other</span>
                            </h1>
                            <p>
                                Let me introduce myself, my workflows, and the technologies I like to
                                use to bring my projects to life.
                            </p>
                        </div>
                        <MouseComponent scrollContainerRef={scrollContainerRef}/>
                    </div>
                </section>

                <section className="section-content-container">
                    <div className="inset-0" style={{ display: "flex", justifyContent: "center", overflow: "unset" }}>
                        <div
                            className="container main-about-container"
                            ref={proxyRef}
                        >



                          <MeComponent mode="me" boundRef={proxyRef} scrollContainerRef={scrollContainerRef}/>
                          <MeComponent mode="where-i-work" boundRef={proxyRef} scrollContainerRef={scrollContainerRef}/>
                          <MeComponent mode="portrait" boundRef={proxyRef} scrollContainerRef={scrollContainerRef}/>
                          <MeComponent mode="me-online" boundRef={proxyRef} scrollContainerRef={scrollContainerRef}/>
                        </div>
                    </div>
                </section>
                <section className="section-content-container">
                    <div className="inset-0 normal-content-container" style={{ display: "flex", justifyContent: "center", overflow: "unset", padding: "1rem" }}>
                        <IntroTitleComponent texts={[{value: "Tech stacks I like to"}, {value: "work with", primary: true}]} scrollContainerRef={scrollContainerRef}/>
                        <TechsComponent scrollContainerRef={scrollContainerRef}/>

                    </div>
                </section>
            </main>
        </ScrollContainer>
    );
};
