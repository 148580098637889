import { Suspense, useEffect, useState } from 'react'
import {
  Navigate,
  Route,
  Routes as RoutesReactRouterDom,
} from 'react-router-dom'
// import { About, Contact, Experience, GenericNotFound, Home, Work } from './paths'
// import { Home as HomeComponent } from './paths';

import {Home} from '../pages/home/home';
import {About} from '../pages/about';
import {Work} from '../pages/work';
import {Experience} from '../pages/experience';
import {Contact} from '../pages/contact';
import {Error404} from '../pages/error404';

import { PageContainer } from '../layout/pageContainer'

import TransitionComponent from '../components/Transition';

const useFirstRender = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return isFirstRender;
};


export const Routes = () => {
  const isFirstRender = useFirstRender();
  return (
    // <Suspense fallback={isFirstRender ? <p></p> : null}>
      <RoutesReactRouterDom>
        <Route path="/" element={<PageContainer/>}>
          <Route index element={<TransitionComponent><Home /></TransitionComponent>} />
          <Route path="about" element={<TransitionComponent><About /></TransitionComponent>}  />
          <Route path="work" element={<TransitionComponent><Work /></TransitionComponent>} />
          <Route path="experience" element={<TransitionComponent><Experience /></TransitionComponent>}/>
          <Route path="contact" element={<TransitionComponent><Contact /></TransitionComponent>} />
        </Route>
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<Error404 />} />
      </RoutesReactRouterDom>
    // </Suspense>
  )
}