import { RefObject, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { timeout } from "../../helpers/common";
import { Draggable } from "gsap/Draggable";
import thaiFlag from '../../assets/images/thailand.webp';
import me1 from '../../assets/images/me1.png';
import { PiArrowUpRightBold } from "react-icons/pi";
import TempControls from "../tempControls";

interface MeComponentProps {
    scrollContainerRef: RefObject<HTMLDivElement>;
    boundRef: RefObject<HTMLDivElement>;
    mode: string;
}

export const MeComponent = ({
    scrollContainerRef,
    boundRef,
    mode,
}: MeComponentProps) => {
    const draggableRef = useRef<HTMLDivElement>(null);
    useGSAP(
        async () => {
            await timeout();

            setAnimateFolder(0);

            Draggable.create(draggableRef.current, {
                bounds: boundRef.current,
                edgeResistance: 0.5,
                type: "y,x",
                inertia: true,
                onDrag: function () {
                    this.update();

                },
                onDragEnd: function () {
                    gsap.to(this.target, {
                        duration: 0.5,
                        x: this.endX,
                        y: this.endY,
                        ease: "power2.out",
                    }).then(() => {
                        setAnimateFolder(this.y)
                    });
                },
            });
        },
        { scope: scrollContainerRef }
    );

    const setAnimateFolder = (y: number) => {
        gsap.killTweensOf(draggableRef.current);

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: draggableRef.current,
                start: "top 90%",
                end: "+=100px",
                    scrub: 1,
            }
        });

        tl.from(draggableRef.current, { translateY: y + 5, opacity: 0 })
            .to(draggableRef.current, {  translateY: y, opacity: 1, ease: "power1.inOut", duration: .25 });
    }

    if (mode === "where-i-work")
        return (
            <div className="folder-container where-i-work" ref={draggableRef}>
                <div className="folder-title-container">
                    <span className="text-sm">where-i-work</span>
                    <TempControls/>
                </div>
                <div className="folder-body-container text-lg">
                    <p>Currently based in Bangkok, Thailand<img style={{ width: "20px", height: "1rem", marginLeft: "5px" }} src={thaiFlag} alt="thai-flag"></img></p>
                </div>
            </div>
        );

    else if (mode === "portrait")
        return (
        <div className="folder-container portrait" ref={draggableRef}>
            <div className="folder-title-container">
                <span className="text-sm">
                    portrait
                </span>
                <TempControls/>
            </div>
            <div className="folder-body-container text-lg">
                <img src={me1} alt="portrait-me"></img>
            </div>
        </div>
    )
    else if (mode === "me-online")
        return (
            <div className="folder-container me-online" ref={draggableRef}>
            <div className="folder-title-container">
                <span className="text-sm">
                    me-online
                </span>
                <TempControls/>
            </div>
            <div className="folder-body-container text-lg">
            <p>
              <a className='btn-icon' href="https://www.linkedin.com/in/apisit-promyos-074311201/" target='_blank' rel="noreferrer" >linkedin<PiArrowUpRightBold size={14}/></a>
            </p>
            <p>
              <a className='btn-icon' href="https://www.instagram.com/tatateetatata/" target='_blank' rel="noreferrer" >instagram<PiArrowUpRightBold size={14}/></a>
            </p>
            <p>
              <a className='btn-icon' href="https://www.facebook.com/ApisitPromyos/" target='_blank' rel="noreferrer" >facebook<PiArrowUpRightBold size={14}/></a>
            </p>
            <p>
              <a className='btn-icon' href="https://github.com/treblemakers" target='_blank' rel="noreferrer" >github<PiArrowUpRightBold size={14}/></a>
            </p>
            </div>
        </div>)
    else
        return (
            <div className="folder-container about-me" ref={draggableRef}>
                <div className="folder-title-container">
                    <span className="text-sm">about-me</span>
                    <TempControls/>
                </div>
                <div className="folder-body-container text-lg">
                    <p>
                        Hi, I'm <span className="b">Apisit</span> , a{" "}
                        <span className="g">software developer</span>!
                    </p>
                    <p>
                        I specialize in{" "}
                        <span className="p">developing projects</span> that
                        precisely match <span className="r">prototypes</span>{" "}
                        and can articulate both{" "}
                        <span className="y">design</span> and{" "}
                        <span className="b">technical</span> specifics.
                    </p>
                    <p>
                        I integrate complex but smooth{" "}
                        <span className="g">animations</span> and{" "}
                        <span className="b2">interactions</span> into my
                        projects to present the website information in an{" "}
                        <span className="r">engaging way</span> and make it a{" "}
                        <span className="b">memorable experience</span>.
                    </p>
                    <p>
                        I strive to deliver projects that are{" "}
                        <span className="p">visually compelling</span> by
                        working closely with{" "}
                        <span className="y">designers</span> but also
                        technically outstanding with{" "}
                        <span className="g">
                            clean and properly structured code
                        </span>
                        .{" "}
                    </p>
                    <p>
                        My favorite approach is{" "}
                        <span className="b">Full-Stack development</span>: while
                        my focus is on <span className="r">front-end</span>{" "}
                        development, I can also{" "}
                        <span className="y">take care of all other stages</span>{" "}
                        of the development process
                    </p>
                </div>
            </div>
        );
};
